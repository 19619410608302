<template>
    <b-card title="Termini">
        <div class="d-flex justify-content-end ">
            <b-button variant="primary" @click="openAddTermModal">
                <span class="flex items-center gap-2">
                    <fa icon="plus" size="xs"/>
                    Dodaj termin
                </span>
            </b-button>
        </div>
        <Table class="mt-2" :columns="columns" :data="items" v-slot="props">
            <span v-if="props.column.display_type === 1">
                <b-badge v-if="props.row.available" variant="success">Da</b-badge>
                <b-badge v-else variant="danger">Ne</b-badge>
            </span>
            <span v-else-if="props.column.display_type === 2">
                <b-badge v-if="props.row.high_school_only" variant="success">Da</b-badge>
                <b-badge v-else variant="danger">Ne</b-badge>
            </span>
            <span v-else-if="props.column.display_type === 3" class="d-flex justify-content-center">
                <b-button variant="warning" @click="openEditTermModal(props.row)"><fa icon="edit"/></b-button>
                <b-button class="ml-1" variant="danger" @click="deleteRow(props.row)"><fa icon="trash"/></b-button>
            </span>
            <span v-else>
                {{ props.formattedRow[props.column.field] }}
            </span>
        </Table>

        <add-term-modal ref="addTermModal" @itemAdded="loadData"/>
        <edit-term-modal ref="editTermModal" @itemEdited="loadData"/>
    </b-card>
</template>

<script>
    import { BCard, BBadge, BButton } from 'bootstrap-vue'
    import Table from '@/views/components/Table.vue'
    import AddTermModal from '@/views/admin/term/components/AddTermModal.vue'
    import EditTermModal from '@/views/admin/term/components/EditTermModal.vue'

    export default {
        components: {
            EditTermModal,
            AddTermModal,
            Table,
            BCard,
            BBadge,
            BButton
        },
        data() {
            return {
                columns: [
                    {
                        field: 'name',
                        label: 'Naziv',
                        tdClass: 'align-middle',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field: 'type',
                        label: 'Tip',
                        tdClass: 'align-middle',
                        formatFn: (value) => {
                            if (value === 1) return 'Obmorsko letovanje'
                            else if (value === 2) return 'Višinsko letovanje'
                            return '/'
                        },
                        filterOptions: {
                            enabled: true,
                            filterDropdownItems: [
                                { value: 1, text: 'Obmorsko letovanje' },
                                { value: 2, text: 'Višinsko letovanje' }
                            ]
                        }
                    },
                    {
                        field: 'available',
                        label: 'Na voljo',
                        tdClass: 'align-middle',
                        display_type: 1,
                        filterOptions: {
                            enabled: true,
                            filterDropdownItems: [
                                { value: true, text: 'Da' },
                                { value: false, text: 'Ne' }
                            ]
                        }
                    },
                    {
                        field: 'date_from',
                        label: 'Datum od',
                        tdClass: 'align-middle',
                        formatFn: (value) => {
                            return this.$dayjs(value).format('DD.MM.YYYY')
                        }
                    },
                    {
                        field: 'date_to',
                        label: 'Datum do',
                        tdClass: 'align-middle',
                        formatFn: (value) => {
                            return this.$dayjs(value).format('DD.MM.YYYY')
                        }
                    },
                    {
                        field: 'high_school_only',
                        label: 'Samo srednja šola',
                        tdClass: 'align-middle',
                        display_type: 2,
                        filterOptions: {
                            enabled: true,
                            filterDropdownItems: [
                                { value: true, text: 'Da' },
                                { value: false, text: 'Ne' }
                            ]
                        }
                    },
                    {
                        field: 'total_slots',
                        label: 'Število mest',
                        tdClass: 'align-middle',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field: 'available_slots',
                        label: 'Število prostih mest',
                        tdClass: 'align-middle',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field: 'actions',
                        label: 'Dejanja',
                        tdClass: 'align-middle',
                        display_type: 3
                    }
                ],
                items: []
            }
        },
        methods: {
            async loadData() {
                try {
                    this.$store.commit('app/START_LOADING')
                    const response = await this.$http.get('/api/private/v1/term/')
                    this.items = response.data ?? []
                } catch (error) {
                    this.$printError('Prišlo je do napake pri nalaganju podatkov')
                } finally {
                    this.$store.commit('app/STOP_LOADING')
                }
            },
            openAddTermModal() {
                this.$refs.addTermModal.show()
            },
            openEditTermModal(data) {
                this.$refs.editTermModal.show(data)
            },
            async deleteRow(data) {
                try {
                    const confirmDelete = await this.$confirmDialog(`Ali res želite izbrisati termin: ${data.name}`)
                    if (!confirmDelete) return
                    this.$store.commit('app/START_LOADING')
                    await this.$http.delete(`/api/private/v1/term/${data.id}`)
                    this.$printSuccess('Brisanje je bilo uspešno')
                    await this.loadData()
                } catch (error) {
                    this.$printError('Prišlo je do napake pri brisanju')
                } finally {
                    this.$store.commit('app/STOP_LOADING')
                }
            }
        },
        async mounted() {
            await this.loadData()
        }
    }
</script>

<style>

</style>
