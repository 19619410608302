<template>
    <b-modal ref="modal" title="Dodaj termin" size="lg" centered no-close-on-backdrop @cancel="makeEmptyObject" @close="makeEmptyObject" @hide="makeEmptyObject">
        <template #default>
            <validation-observer ref="validation">
                <b-col cols="12">
                    <b-alert class="p-1" variant="warning" show>Kreiranje novega termina bo ustvarilo tudi termin, ki bo na spletni strani prikazan kot "Čakalna lista [IME TEGA TERMINA]". Novo ustvarjen termin za čakalno listo lahko urejate ali izbrišete, kot vse ostale termine.</b-alert>
                </b-col>
                <b-form-group>
                    <b-form-checkbox switch v-model="addTermObject.available">Na voljo</b-form-checkbox>
                </b-form-group>
                <b-form-group>
                    <b-form-checkbox switch v-model="addTermObject.high_school_only">Samo srednja šola</b-form-checkbox>
                </b-form-group>
                <b-form-group label="Naziv">
                    <validation-provider #default="{errors}" name="Naziv" rules="required|min:1|max:100">
                        <b-form-input v-model="addTermObject.name"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-row>

                    <b-col cols="12" md="6">
                        <b-form-group label="Tip">
                            <validation-provider #default="{errors}" name="Tip" rules="required">
                                <vue-select v-model="addTermObject.type" label="text" :reduce="x => x.value" :options="typeOptions" :clearable="false"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group label="Število mest">
                            <validation-provider #default="{errors}" name="Število mest" rules="required|positive">
                                <b-form-input v-model.number="addTermObject.total_slots" type="number"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-form-group label="Datum od">
                            <validation-provider #default="{errors}" name="Datum od" rules="required">
                                <b-form-datepicker v-model="addTermObject.date_from"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group label="Datum do">
                            <validation-provider #default="{errors}" name="Datum do" rules="required">
                                <b-form-datepicker v-model="addTermObject.date_to"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>
            </validation-observer>
        </template>

        <template #modal-footer="{ hide }">
            <b-button variant="outline-primary" @click="hide">
                Zapri
            </b-button>
            <b-button variant="primary" @click="add">
                Dodaj
            </b-button>
        </template>
    </b-modal>
</template>

<script>
    import {BButton, BModal, BFormGroup, BFormInput, BFormDatepicker, BCol, BRow, BFormCheckbox, BAlert} from 'bootstrap-vue'
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {required, min, max} from '@core/utils/validations/validations'
    import VueSelect from 'vue-select'

    export default {
        components: {
            BRow,
            BCol,
            BModal,
            BButton,
            BFormGroup,
            BFormInput,
            BFormDatepicker,
            BFormCheckbox,
            BAlert,
            ValidationProvider,
            ValidationObserver,
            VueSelect
        },
        data() {
            return {
                addTermObject: {
                    name: '',
                    type: 1,
                    available: false,
                    date_from: '',
                    date_to: '',
                    high_school_only: false,
                    total_slots: ''
                },
                typeOptions: [
                    {text: 'Obmorsko letovanje', value: 1},
                    {text: 'Višinsko letovanje', value: 2}
                ],
                required,
                min,
                max
            }
        },
        methods: {
            show() {
                this.$refs.modal.show()
            },
            async add() {
                const isValid = await this.$refs.validation.validate()
                if (!isValid) return

                try {
                    this.$store.commit('app/START_LOADING')
                    const response = await this.$http.post('/api/private/v1/term/', this.addTermObject)
                    this.$refs.modal.hide()
                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess('Dodajanje je bilo uspešno')
                    this.makeEmptyObject()
                } catch (error) {
                    this.$printError('Prišlo je do napake pri dodajanju')
                } finally {
                    this.$store.commit('app/STOP_LOADING')
                }
            },
            makeEmptyObject() {
                this.addTermObject = this.$options.data.call(this).addTermObject
            }
        },
        watch: {
            'addTermObject.date_from'(val) {
                this.addTermObject.date_from = this.$dayjs(val).format()
            },
            'addTermObject.date_to'(val) {
                this.addTermObject.date_to = this.$dayjs(val).format()
            }
        }

    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
